import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {motion} from 'framer-motion'
import {KTSVG} from '../../../../../_metronic/helpers'
import useAuthContext from '../../../../Auth/AuthContext'
import {FormControl, MenuItem, Select} from '@mui/material'

export const AddBans = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [fileName, setFileName] = useState('No selected file')
  const navigate = useNavigate()
  const {check_role, getUser} = useAuthContext()

  const [data, setData] = useState({
    name: '',
    value: '',
    type: 'ip_ban',
  })

  const types = [
    {id: 'ban_ip', name: intl.formatMessage({id: 'Ban.IP'})},
    {id: 'ban_device', name: intl.formatMessage({id: 'Ban.Device'})},
    {id: 'ban_account', name: intl.formatMessage({id: 'Ban.Account'})},
    {id: 'other', name: intl.formatMessage({id: 'Ban.Other'})},
  ]

  const handleChange = (e) => {
    const {name, value} = e.target
    setData((prevData) => ({...prevData, [name]: value}))
  }

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()

    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data')
    
    // تأكد من تمرير القيم الفارغة وليس تجاهلها
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value !== undefined ? value : '')
    })

    try {
      await axiosInstance.post(`/Admin-bans`, formData).then((response) => {
        Swal.fire({
          title: intl.formatMessage({id: 'Form.Create'}),
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setLoading(false)
        setErrors([])
        navigate(`/Bans/Bans`)
      })
    } catch (e) {
      if (e.response && e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
}


  useEffect(() => {
    if (!check_role('add-ban')) {
      navigate('/')
    }
    getUser()
  }, [])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Currency.Create'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <span className='text-muted text-hover-primary'>
                {intl.formatMessage({id: 'Menu.Home'})}
              </span>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Currencies'})}
            </li>
          </ul>
        </div>
        <div>
          <Link to='/Bans/Bans' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Form.GoBack'})}
          </Link>
        </div>
      </div>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <form
          onSubmit={handleEdit_Add_submit}
          className='form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework'
        >
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'Form.General'})} </h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='row flex-between'>
                  <div className='mb-3 col-6 v-row fv-plugins-icon-container'>
                    <label className='form-label'>{intl.formatMessage({id: 'Ban.Name'})}</label>
                    <input
                      type='text'
                      name='name'
                      className='form-control py-4'
                      value={data.name}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({id: 'From.Name'})}
                    />
                    <div className='fv-plugins-message-container invalid-feedback' />
                    {errors.name_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.name_en}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='mt-2 col-6 v-row fv-plugins-icon-container'>
                    {intl.formatMessage({id: 'Form.Type'})}
                    <FormControl fullWidth>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='type'
                        onChange={handleChange}
                        value={data.type}
                      >
                        {types.map((row, key) => (
                          <MenuItem key={key} value={row.id}>
                            {row.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {data.type === 'other' && (
                    <div className='mb-3 fv-row fv-plugins-icon-container'>
                      <label className='form-label'>{intl.formatMessage({id: 'From.Value'})}</label>
                      <input
                        type='text'
                        name='value'
                        className='form-control py-4'
                        value={data.value}
                        onChange={handleChange}
                        placeholder={intl.formatMessage({id: 'Ban.Value'})}
                      />
                      <div className='fv-plugins-message-container invalid-feedback' />
                      {errors.value && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.value}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end '>
              <button type='submit' className='btn btn-primary'>
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'Form.Create'})}{' '}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'Form.Pleasewait'})}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </motion.nav>
  )
}
