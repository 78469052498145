import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton, Pagination} from '@mui/material' // استيراد Pagination
import useAuthContext from '../../../../Auth/AuthContext'

const HomeSliders = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const [filteredItems, setFilteredItems] = useState([]) // حالة للعناصر المصفاة
  const [search, setSearch] = useState('') // حالة لحقل البحث
  const [currentPage, setCurrentPage] = useState(1) // حالة للصفحة الحالية
  const itemsPerPage = 5 // عدد العناصر في كل صفحة

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      <div className='col-auto'>
        {check.add && (
          <Link to='/WebDetails/Add-HomeSliders' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'HomeSliders.Create'})}
          </Link>
        )}
      </div>
    </div>
  )

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin-details', {
      params: {
        type: 'home_sliders',
      },
    })
    setItems(data)
    setFilteredItems(data) 
    setLoading(false)
  }

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase()
    setSearch(value)

    const filtered = Items.filter(
      (item) =>
        item.name_en?.toLowerCase().includes(value) ||
        item.name_ar?.toLowerCase().includes(value) ||
        item.description_en?.toLowerCase().includes(value) ||
        item.description_ar?.toLowerCase().includes(value)
    )
    setFilteredItems(filtered)
    setCurrentPage(1) 
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.delete(`/admin-details/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.Redirect'}),
      accessor: 'Redirect',
      Cell: (row) => (
        <div className='w-100 flex-center'>
          {row.row.original.cat
            ? 'Category: ' + row.row.original.cat.name_en
            : row.row.original.item
            ? 'Product: ' + row.row.original.item.name_en
            : '---'}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.File'}),
      accessor: 'File',
      Cell: (row) => (
        <div className='symbol symbol-50px'>
          <img
            src={
              row.row.original.img
                ? process.env.REACT_APP_IMAGE_PATH + row.row.original.img
                : toAbsoluteUrl('media/avatars/300-6.jpg')
            }
            alt=''
          />
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/WebDetails/Edit-HomeSliders/${row.row.original.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => Delete(row.row.original.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-webDetails'),
      add: check_role('add-webDetails'),
      show: check_role('show-webDetails'),
      edit: check_role('edit-webDetails'),
      delete: check_role('delete-webDetails'),
    })

    if (!check_role('browse-webDetails')) {
      navigate('/')
    }
    get_data()
  }, [])

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : []

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  const renderCardView = () => {
    return (
      <>
        <div className='mb-5'>
          <div className="mb-3">
            <CustomDiv />
          </div>
          <input
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({id: 'Search'})}
            value={search}
            onChange={handleSearch}
          />
        </div>

        <div className='row'>
          {currentItems.map((item) => (
            <div className='col-12 mb-3' key={item.id}>
              <div className='card shadow-sm border-light'>
                <div className='card-body'>
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.Redirect'})}:</strong>{' '}
                    {item.cat
                      ? 'Category: ' + item.cat.name_en
                      : item.item
                      ? 'Product: ' + item.item.name_en
                      : '---'}
                  </p>
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.File'})}:</strong>
                    <img
                      src={
                        item.img
                          ? process.env.REACT_APP_IMAGE_PATH + item.img
                          : toAbsoluteUrl('media/avatars/300-6.jpg')
                      }
                      alt=''
                      className='img-thumbnail'
                      style={{width: '100px', height: '100px', objectFit: 'cover'}}
                    />
                  </p>
                  <div className='mt-3'>
                    {check.edit && (
                      <Link
                        to={`/WebDetails/Edit-HomeSliders/${item.id}`}
                        className='btn btn-primary me-2 col-5'
                      >
                        {intl.formatMessage({id: 'Table.Edit'})}
                      </Link>
                    )}
                    {check.delete && (
                      <button onClick={() => Delete(item.id)} className='btn btn-danger col-5'>
                        {intl.formatMessage({id: 'Table.Delete'})}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination for Cards */}
        <div className='mt-6 d-flex justify-content-center'>
          <Pagination
            count={Math.ceil(filteredItems.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color='primary'
          />
        </div>
      </>
    )
  }

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.HomeSliders'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.HomeSliders'})}
            </li>
          </ul>
        </div>
      </div>

      {Items !== null && !loading ? (
        <>
          <div className='d-none d-lg-block'>
            <ReactTable columns={columns} data={filteredItems} customComponent={<CustomDiv />} />
          </div>
          <div className='d-lg-none'>{renderCardView()}</div>
        </>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </motion.nav>
  )
}

export default HomeSliders
