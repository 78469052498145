import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {motion} from 'framer-motion'
import {KTSVG} from '../../../../../_metronic/helpers'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import UsersAutoComplete from '../../../../../_metronic/partials/widgets/AutoComplete/UsersAutoComplete'
import useAuthContext from '../../../../Auth/AuthContext'

export const AddBanUsers = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [fileName, setFileName] = useState('No selected file')
  const navigate = useNavigate()
  const [imported, setImported] = useState([])
  const [Items, setItems] = useState([])
  const {check_role ,getUser} = useAuthContext()

  const [user_value, setUSER_Value] = useState(null)


  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data')
    formData.append('data', JSON.stringify(imported))
    formData.append('user_id', user_value.id)
    try {
      await axiosInstance.post(`Admin-ban-users`, formData).then((response) => {
        Swal.fire({
          title: 'Success',
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setLoading(false)
        setErrors([])
        navigate(-1)
      })
    } catch (e) {
      console.log(e.response.data.errors)
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  const get_data = () => {
    setItems([])
    axiosInstance.get('/Admin-bans').then((response) => {
      setItems(response.data)
    })
  }

  const add_item = (item) => {
    const rowsInput = {
      id: item.id,
      type: item.type,
      value: item.value,
    }
    setImported((prevImported) => [...prevImported, rowsInput])
  }

  const remove_item = (item) => {
    setImported((prevImported) =>
      prevImported.filter((importedItem) => importedItem.id !== item.id)
    )
  }

  const handle_change = (e, item) => {
    if (e.target.checked) {
      add_item(item)
    } else {
      remove_item(item)
    }
  }

  const isChecked = (id) => {
    return imported.some((importedItem) => importedItem.id === id)
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: (row) => (
        <div className='w-100 flex-center'>
          <div className='form-check form-check-sm form-check-custom form-check-solid flex-center'>
            <input
              className='form-check-input widget-9-check me-2'
              onChange={(e) => {
                handle_change(e, row.row.original)
              }}
              type='checkbox'
              checked={isChecked(row.row.original.id)}
            />
            # {row.row.original.id}
          </div>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Name'}),
      accessor: 'name',   
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Type'}),
      accessor: 'type', 
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.type}</div>,
    },
  ]
  // useEffect(() => {
  //   if (!check_role('add-users-roles')) {
  //     navigate('/')
  //   }
  //   get_data()
  // }, [])


  useEffect(() => {
    if (!check_role('add-ban-users')) {
      navigate('/')
    }
    get_data()
  }, [])


  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'UserRole.Create'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <span className='text-muted text-hover-primary'>
                {' '}
                {intl.formatMessage({id: 'Menu.Home'})}
              </span>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Roles'})}</li>
          </ul>
        </div>
        <div>
          <button onClick={(e) => navigate(-1)} type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Form.GoBack'})}
          </button>
        </div>
      </div>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <form
          onSubmit={handleEdit_Add_submit}
          id='kt_ecommerce_add_category_form'
          className='form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework'
          data-kt-redirect='../../demo1/dist/apps/ecommerce/catalog/categories.html'
        >
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'Form.General'})} </h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='row flex-between'>
                  <div className='mb-3 col-12 fv-row fv-plugins-icon-container'>
                    <label className='required form-label'>
                      {' '}
                      {intl.formatMessage({id: 'Form.Select_User'})}{' '}
                    </label>
                    <UsersAutoComplete value={user_value} setValue={setUSER_Value} />
                  </div>
                </div>
              </div>
            </div>
            <div className='card my-2'>
              <ReactTable columns={columns} data={Items} customComponent={<></>} />
            </div>
            <div className='d-flex justify-content-end '>
              <button type='submit' className='btn btn-primary'>
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'Form.Create'})}{' '}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'Form.Pleasewait'})}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </motion.nav>
  )
}
