import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton} from '@mui/material'
import Pagination from '@mui/material/Pagination' // Importing Pagination component
import useAuthContext from '../../../../Auth/AuthContext'

const BansDevice = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const [filteredItems, setFilteredItems] = useState(null) // State for filtered items
  const [searchTerm, setSearchTerm] = useState('') // State for search input
  const [currentPage, setCurrentPage] = useState(1) // State for current page
  const itemsPerPage = 10 // Number of items per page

  const navigate = useNavigate()

  const {check_role} = useAuthContext()

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/Bans-Add-Device' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Create Device'})}
          </Link>
        </div>
      )}
    </div>
  )

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/Admin-global-ban-device')
    setItems(data)
    setFilteredItems(data) // Initialize filtered items with all data
    setLoading(false)
  }

  const edit_status = async (value, id) => {
    let endpoint = `/change-currency-status/${id}/${value}`
    try {
      await axiosInstance.get(endpoint)
      get_data()
    } catch (error) {
      console.error(error)
    }
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.delete(`/Admin-global-ban-device/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  // Handle search input
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchTerm(value)
    const filtered = Items.filter((item) => item.value.toLowerCase().includes(value))
    setFilteredItems(filtered)
    setCurrentPage(1) // Reset to first page on new search
  }

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : []

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: (row) => <div className=' w-100 flex-center'># {row.row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Value'}),
      accessor: 'value',
      Cell: (row) => <div className=' w-100 flex-center'>{row.row.original.value}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Bans-Edite-Device/${row.row.original.id}`}
              className='btn me-2  btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => Delete(row.row.original.id)}
              className='btn me-2  btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-ban-devices'),
      add: check_role('add-ban-devices'),
      show: check_role('show-ban-devices'),
      edit: check_role('edit-ban-devices'),
      delete: check_role('delete-ban-devices'),
    })

    if (!check_role('browse-ban-devices')) {
      navigate('/')
    }

    get_data()
  }, [])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Devices'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Devices'})}
            </li>
          </ul>
        </div>
      </div>

      {/* Table View for Large Screens */}
      <div className='d-none d-lg-block'>
        {Items !== null && !loading ? (
          <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
        ) : (
          <div className='row'>
            <div className='col-12'>
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
              />
            </div>
          </div>
        )}
      </div>

      {/* Card View for Small Screens */}
      <div className='d-lg-none'>
        {Items !== null && !loading ? (
          <>
            {/* Search Input */}
            <div className='mb-4'>
              <div className='mb-3'>
                <CustomDiv />
              </div>
              <input
                type='text'
                className='form-control'
                placeholder={intl.formatMessage({id: 'Search.Input'})}
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>

            <div className='row'>
              {currentItems.map((item) => (
                <div className='col-12 mb-3' key={item.id}>
                  <div className='card'>
                    <div className='card-body'>
                      <h5 className='card-title'>ID: #{item.id}</h5>
                      <p className='card-text'>Value: {item.value}</p>
                      <div className='d-flex'>
                        {check.edit && (
                          <Link
                            to={`/Bans-Edite-Device/${item.id}`}
                            className='btn btn-light-primary me-2 col-5'
                          >
                            {intl.formatMessage({id: 'Table.Edit'})}
                          </Link>
                        )}
                        {check.delete && (
                          <button
                            onClick={() => Delete(item.id)}
                            className='btn btn-light-danger col-5'
                          >
                            {intl.formatMessage({id: 'Table.Delete'})}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <div className='mt-6 d-flex justify-content-center'>
              <Pagination
                count={Math.ceil(filteredItems.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color='primary'
              />
            </div>
          </>
        ) : (
          <div className='row'>
            <div className='col-12'>
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
              />
            </div>
          </div>
        )}
      </div>
    </motion.nav>
  )
}

export default BansDevice
