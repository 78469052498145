import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {FormControl, MenuItem, Pagination, Select, Skeleton} from '@mui/material'
import useAuthContext from '../../../../Auth/AuthContext'

const PermissionsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [filteredItems, setFilteredItems] = useState([]) // Add filteredItems state
  const [category_id, setCategory_ID] = useState('all')
  const [Cats, setCats] = useState([])
  const [searchTerm, setSearchTerm] = useState('') // Add searchTerm state

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10 // Number of users per page

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const CustomDiv = () => (
    <div className='row justify-content-between align-items-center w-100'>
      <div className='col-12 col-md-6 mb-3 mb-md-0'>
        <FormControl fullWidth>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            onChange={(e) => setCategory_ID(e.target.value)}
            value={category_id}
          >
            {Cats.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row.name_en}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className='col-12 col-md-auto text-md-end text-center mt-3 mt-md-0'>
        {check.add && (
          <Link to='/Roles/Add-Permissions' type='button' className='btn btn-primary py-5'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Permissions.Create'})}
          </Link>
        )}
      </div>
    </div>
  )

  const getData = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin-permissions', {
      params: {type: category_id},
    })
    setItems(data)
    setFilteredItems(data) // Set filteredItems to the initial data
    setLoading(false)
  }

  const getCats = async () => {
    setCats([])
    setCats([{id: 'all', name_en: 'All'}])
    const {data} = await axiosInstance.get('/admin-permissions-categories')
    setCats((prevItems) => [...prevItems, ...data])
  }

  const deleteItem = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.delete(`/admin-permissions/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchTerm(value)

    // Filter items based on search term
    const filtered = items.filter(
      (item) =>
        item.value.toLowerCase().includes(value) ||
        item.name_en.toLowerCase().includes(value) ||
        item.name_ar.toLowerCase().includes(value)
    )
    setFilteredItems(filtered)
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: ({row}) => <div className='w-100 flex-center'># {row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Value'}),
      accessor: 'value',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.value}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.DescriptionEN'}),
      accessor: 'name_en',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name_en}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.DescriptionAR'}),
      accessor: 'name_ar',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name_ar}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Roles/Edit-Permissions/${row.original.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => deleteItem(row.original.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-permissions'),
      add: check_role('add-permissions'),
      show: check_role('show-permissions'),
      edit: check_role('edit-permissions'),
      delete: check_role('delete-permissions'),
    })

    if (!check_role('browse-permissions')) {
      navigate('/')
    }
    getData()
    getCats()
  }, [category_id])

  // Calculate the users to display on the current page
  const indexOfLastUser = currentPage * itemsPerPage
  const indexOfFirstUser = indexOfLastUser - itemsPerPage
  const currentUsers = filteredItems.slice(indexOfFirstUser, indexOfLastUser)

  // Handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Permissions'})}
          </h1>
        </div>
      </div>

      {!loading ? (
        <>
          {/* Table for large screens */}
          <div className='d-none d-lg-block'>
            <ReactTable columns={columns} data={currentUsers} customComponent={<CustomDiv />} />
           
          </div>

          {/* Cards for small screens */}
          <div className='d-block d-lg-none'>
            {/* Search Input */}
            <div className='input-group mb-3'>
              <input
                type='text'
                className='form-control'
                placeholder='Search'
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>

            <div className='mb-4'>
              <CustomDiv />
            </div>

            <div className='row'>
              {currentUsers.map((item) => (
                <div className='col-12 mb-4' key={item.id}>
                  <div className='card'>
                    <div className='card-body'>
                      <h5 className='card-title'>ID: {item.id}</h5>
                      <p className='card-text'>Value: {item.value}</p>
                      <p className='card-text'>Description (EN): {item.name_en}</p>
                      <p className='card-text'>Description (AR): {item.name_ar}</p>
                      <div className='d-flex'>
                        {check.edit && (
                          <Link
                            to={`/Roles/Edit-Permissions/${item.id}`}
                            className='btn me-2 btn-light-primary col-5'
                          >
                            {intl.formatMessage({id: 'Table.Edit'})}
                          </Link>
                        )}
                        {check.delete && (
                          <button
                            onClick={() => deleteItem(item.id)}
                            className='btn me-2 btn-light-danger col-5'
                          >
                            {intl.formatMessage({id: 'Table.Delete'})}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              
            {/* Pagination for cards */}
            <div className='mt-6 d-flex justify-content-center'>
              <Pagination
                count={Math.ceil(filteredItems.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color='primary'
              />
            </div>
            </div>

          </div>
        </>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </motion.nav>
  )
}

export default PermissionsHome
