import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate, useParams} from 'react-router-dom'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {motion} from 'framer-motion'
import {KTSVG} from '../../../../../_metronic/helpers'
import {MdCloudUpload} from 'react-icons/md'
import useAuthContext from '../../../../Auth/AuthContext'
import {FormControl, MenuItem, Select} from '@mui/material'

export const UpdateLevels = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const navigate = useNavigate()
  const {id} = useParams()

  const {check_role} = useAuthContext()
  const types = [
    {id: 'files', name: intl.formatMessage({id: 'Form.Files'})},
    {id: 'custom', name: intl.formatMessage({id: 'Form.custom'})},
  ]
  const cycles = [
    {id: 'user_verification', name: intl.formatMessage({id: 'Form.user_verification'})},
    {id: 'user_credits', name: intl.formatMessage({id: 'Form.user_credits'})},
  ]

  const [rowsData, setRowsData] = useState([
    {
      des_en: '',
      des_ar: '',
    },
  ])

  const [data, setData] = useState({
    img: null,
    name_en: null,
    name_ar: null,
    daily_limit: 0,
    monthly_limit: 0,
    requirements_type: 'files',
    user_column: null,
    monthly_limit: 0,
    des_en: null,
    des_ar: null,
    url: null,
  })

  const addTableRows = () => {
    const rowsInput = {
      des_en: '',
      des_ar: '',
    }
    setRowsData([...rowsData, rowsInput])
  }

  const deleteTableRows = (index) => {
    const rows = [...rowsData]
    rows.splice(index, 1)
    setRowsData(rows)
  }

  const handleChange2 = (index, evnt) => {
    const {name, value} = evnt.target
    const rowsInput = [...rowsData]
    rowsInput[index][name] = value
    setRowsData(rowsInput)
    console.log(rowsData)
  }

  const handleChange = (e) => {
    const {name, type, checked, value} = e.target
    const fieldValue = type === 'checkbox' ? checked : value
    setData((prevData) => ({...prevData, [name]: fieldValue}))
  }

  const changeHandler_iamge = (event) => {
    const name = event.target.name
    setData((prevData) => ({...prevData, [name]: event.target.files[0]}))
  }

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data')
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append('details', JSON.stringify(rowsData))
    formData.append('_method', 'PATCH')
    try {
      await axiosInstance.post(`/admin-levels/${id}`, formData).then((response) => {
        Swal.fire({
          title: 'Success',
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setLoading(false)
        setErrors([])
        navigate(-1)
      })
    } catch (e) {
      console.log(e.response.data.errors)
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  const get_data = async () => {
    await axiosInstance.get(`/admin-levels/${id}`).then(function (res) {
      const response = res.data
      setData({
        img: null,
        img_name: response.img,
        name_en: response.name_en,
        name_ar: response.name_ar,
        daily_limit: response.daily_limit,
        monthly_limit: response.monthly_limit,
        requirements_type: response.type,
        user_column: response.user_column,
        escap_admin_approve: response.escap_admin_approve === 1 ? true : false,
        monthly_limit: response.monthly_limit,
        des_en: response.des_en ?? null,
        des_ar: response.des_ar ?? null,
        url: response.url ?? null,
      })
      if (response.requirements.length > 0) {
        setRowsData(response.requirements)
      }
    })
  }

  useEffect(() => {
    if (!check_role('show-levels')) {
      navigate('/')
    }

    get_data()
  }, [])
  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Level.Edit'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <span className='text-muted text-hover-primary'>
                {' '}
                {intl.formatMessage({id: 'Menu.Home'})}
              </span>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Levels'})}
            </li>
          </ul>
        </div>
        <div>
          <button onClick={() => navigate(-1)} type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Form.GoBack'})}
          </button>
        </div>
      </div>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <form
          onSubmit={handleEdit_Add_submit}
          id='kt_ecommerce_add_category_form'
          className='form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework'
          data-kt-redirect='../../demo1/dist/apps/ecommerce/catalog/categories.html'
        >
          <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
            {/*  */}
            <div className='card card-flush py-4' data-select2-id='select2-data-132-hyjc'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2> {intl.formatMessage({id: 'Form.ShowStatus'})} </h2>
                </div>
                <div className='card-toolbar'>
                  <div
                    className='rounded-circle w-15px h-15px bg-primary'
                    id='kt_ecommerce_add_product_status'
                  />
                </div>
              </div>
              {/*  */}
              <div className='card-body py-0'>
                <div className='form-check form-switch form-check-custom form-check-solid '>
                  <input
                    className='form-check-input h-30px w-50px'
                    onChange={handleChange}
                    type='checkbox'
                    checked={data.escap_admin_approve}
                    id='flexSwitch30x50'
                    name='escap_admin_approve'
                  />
                  <label className='form-check-label' for='flexSwitch20x30'>
                    {intl.formatMessage({id: 'Form.escap_admin_approve'})}{' '}
                    {data.escap_admin_approve
                      ? intl.formatMessage({id: 'Form.Enabled'})
                      : intl.formatMessage({id: 'Form.Disabled'})}
                  </label>
                </div>
              </div>
           
            </div>
            {/*  */}
          
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'Form.ImageContent'})} </h2>
                </div>
              </div>

              <div className='card-body text-center pt-0'>
                <div
                  className='image-input image-input-empty image-input-outline image-input-placeholder mb-3'
                  data-kt-image-input='true'
                >
                  <div className='file'>
                    <form onClick={() => document.querySelector('.input-field2').click()}>
                      <input
                        type='file'
                        accept='image/*'
                        className='input-field2'
                        name='img'
                        hidden
                        onChange={changeHandler_iamge}
                      />
                      {data.img === null && data.img_name ? (
                        <img
                          src={process.env.REACT_APP_IMAGE_PATH + data.img_name}
                          width={150}
                          height={150}
                          alt=''
                        />
                      ) : data.img ? (
                        <img src={URL.createObjectURL(data.img)} width={150} height={150} alt='' />
                      ) : (
                        <>
                          <MdCloudUpload color='#1475cf' size={60} />
                          <p> {intl.formatMessage({id: 'Form.BrowseFilestoupload'})} </p>
                        </>
                      )}
                    </form>
                    {errors.img && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.img}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'Form.General'})} </h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='row flex-between'>
                  <div className='mb-3 col-6 fv-row fv-plugins-icon-container'>
                    <label className='required form-label'>
                      {' '}
                      {intl.formatMessage({id: 'Form.DiscretionEN'})}{' '}
                    </label>
                    <input
                      type='text'
                      name='name_en'
                      className='form-control mb-2'
                      value={data.name_en}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({id: 'Form.DiscretionEN'})}
                    />
                    <div className='fv-plugins-message-container invalid-feedback' />
                    {errors.name_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.name_en}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='mb-3 col-6 fv-row fv-plugins-icon-container'>
                    <label className='required form-label'>
                      {' '}
                      {intl.formatMessage({id: 'Form.DiscretionAR'})}
                    </label>
                    <input
                      type='text'
                      name='name_ar'
                      className='form-control mb-2'
                      value={data.name_ar}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({id: 'Form.DiscretionAR'})}
                    />
                    <div className='fv-plugins-message-container invalid-feedback' />
                    {errors.name_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.name_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='mb-3 col-6 fv-row fv-plugins-icon-container'>
                    <label className='required form-label'>
                      {' '}
                      {intl.formatMessage({id: 'Form.DailyLimit'})}{' '}
                    </label>
                    <input
                      type='number'
                      name='daily_limit'
                      className='form-control mb-2'
                      value={data.daily_limit}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({id: 'Form.DailyLimit'})}
                    />
                    <div className='fv-plugins-message-container invalid-feedback' />
                    {errors.daily_limit && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.daily_limit}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='mb-3 col-6 fv-row fv-plugins-icon-container'>
                    <label className='required form-label'>
                      {' '}
                      {intl.formatMessage({id: 'Form.MonthlyLimit'})}
                    </label>
                    <input
                      type='number'
                      name='monthly_limit'
                      className='form-control mb-2'
                      value={data.monthly_limit}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({id: 'Form.MonthlyLimit'})}
                    />
                    <div className='fv-plugins-message-container invalid-feedback' />
                    {errors.monthly_limit && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.monthly_limit}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='card card-flush py-4'>
              <div className='p-4 px-5 '>
                <div className='card-title col-12'>
                  <div className='row flex-between align-items-center'>
                    <div className='col-6 px-5'>
                      <h2>{intl.formatMessage({id: 'Form.requirements'})}</h2>
                    </div>
                    <div className='col-6'>
                      <label className='required form-label'>
                        {' '}
                        {intl.formatMessage({id: 'Form.requirement_Type'})}
                      </label>
                      <FormControl fullWidth>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          name='requirements_type'
                          onChange={handleChange}
                          value={data.requirements_type}
                        >
                          {types.map((row, key) => (
                            <MenuItem key={key} value={row.id}>
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-body pt-0'>
                {data.requirements_type === 'files' && (
                  <div className='row'>
                    <div className='mb-3 fv-row fv-plugins-icon-container mt-3 '>
                      <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                        <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                          <tr>
                            <th>{intl.formatMessage({id: 'Form.DiscretionEN'})}</th>
                            <th>{intl.formatMessage({id: 'Form.DiscretionAR'})}</th>
                            <th>
                              <button
                                type='button'
                                className='btn me-2 btn-light-primary'
                                onClick={addTableRows}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rowsData.map((row, key) => (
                            <tr key={key}>
                              <td style={{width: '400px'}}>
                                <input
                                  type='text'
                                  value={row.des_en}
                                  onChange={(evnt) => handleChange2(key, evnt)}
                                  name='des_en'
                                  className='form-control'
                                />
                              </td>
                              <td style={{width: '400px'}}>
                                <input
                                  type='text'
                                  value={row.des_ar}
                                  onChange={(evnt) => handleChange2(key, evnt)}
                                  name='des_ar'
                                  className='form-control'
                                />
                              </td>
                              <td>
                                <div className='d-flex flex-center'>
                                  <button
                                    type='button'
                                    className='btn me-2 btn-light-primary'
                                    onClick={addTableRows}
                                  >
                                    +
                                  </button>
                                  <button
                                    className='btn me-2 btn-light-danger'
                                    type='button'
                                    onClick={() => deleteTableRows(key)}
                                  >
                                    x
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {data.requirements_type === 'custom' && (
                  <div className='row'>
                    <div className='mb-3 fv-row fv-plugins-icon-container mt-3 col-lg-12 col-sm-12'>
                      <label className='required form-label'>
                        {' '}
                        {intl.formatMessage({id: 'Form.Cycles'})}
                      </label>
                      <FormControl fullWidth>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          name='user_column'
                          onChange={handleChange}
                          value={data.user_column}
                        >
                          {cycles.map((row, key) => (
                            <MenuItem key={key} value={row.id}>
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <div className='fv-plugins-message-container invalid-feedback' />
                      {errors.user_column && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.user_column}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='mb-3 col-12 fv-row fv-plugins-icon-container'>
                      <label className='required form-label'>
                        {' '}
                        {intl.formatMessage({id: 'Form.url'})}{' '}
                      </label>
                      <input
                        type='text'
                        name='url'
                        className='form-control mb-2'
                        value={data.url}
                        onChange={handleChange}
                        placeholder={intl.formatMessage({id: 'Form.url'})}
                      />
                      <div className='fv-plugins-message-container invalid-feedback' />
                      {errors.url && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.url}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='mb-3 fv-row fv-plugins-icon-container mt-3 col-lg-6 col-sm-12'>
                      <div>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'Form.DiscretionEN'})}
                        </label>
                        <textarea
                          name='des_en'
                          className='form-control mb-2'
                          maxLength='250'
                          onChange={handleChange}
                          cols='30'
                          rows='5'
                          value={data.des_en}
                        ></textarea>
                        {errors.des_en && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.des_en}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='mb-3 fv-row fv-plugins-icon-container mt-3 col-lg-6 col-sm-12'>
                      <div>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'Form.DiscretionAR'})}
                        </label>
                        <textarea
                          name='des_ar'
                          className='form-control mb-2'
                          maxLength='250'
                          onChange={handleChange}
                          cols='30'
                          rows='5'
                          value={data.des_ar}
                        ></textarea>
                        {errors.des_ar && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.des_ar}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='d-flex justify-content-end '>
              <button type='submit' className='btn btn-primary'>
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'Form.Edit'})}{' '}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'Form.Pleasewait'})}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </motion.nav>
  )
}
