import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import axiosInstance from '../../../../api/axios';
import { useEffect, useState } from 'react';

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 11,
    color: '#000',
    backgroundColor: '#ffffff',
  },
  header: {
    marginBottom: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 15,
    borderBottomWidth: 2,
    borderColor: '##24818e', // لون أزرق للخط السفلي
  },
  invoiceTitle: {
    fontSize: 28, // تكبير حجم عنوان الفاتورة
    fontWeight: 'bold',
    textAlign: 'right',
    color: '#24818e', // لون أزرق للعناوين
  },
  logo: {
    width: 100, // حجم الشعار
    height: 50, // ارتفاع الشعار
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#007BFF', // استخدام اللون الأزرق للعناوين الفرعية
    marginBottom: 8,
  },
  textRight: {
    textAlign: 'right',
    color: '#000',
    paddingBottom: 5,
  },
  section: {
    marginBottom: 30,
    paddingBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 30,
    borderWidth: 1,
    borderColor: '#E0E0E0', // لون خفيف لإطار الجدول
    borderRadius: 8, // إضافة حواف دائرية للجدول
    overflow: 'hidden', // لإخفاء الحواف
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 10, // زيادة التباعد بين الصفوف
  },
  tableColHeader: {
    width: '25%',
    backgroundColor: '#f0f8ff', // لون خلفية خفيف للعناوين
    textAlign: 'center',
    padding: 8,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#000', // لون النص داخل الجدول
  },
  tableCol: {
    width: '25%',
    padding: 8,
    textAlign: 'center',
    fontSize: 11,
    backgroundColor: '#fdfdfd', // لون خفيف للخلفية لتباين أفضل
  },
  totalsSection: {
    marginTop: 20,
    textAlign: 'right',
    fontWeight: 'bold',
  },
  totalsRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 5,
    paddingRight: 10,
  },
  totalsLabel: {
    width: '50%',
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: 14, // تكبير حجم الخط في الجزء الخاص بالمبالغ
  },
  totalsValue: {
    width: '20%',
    textAlign: 'right',
    fontSize: 14, // تكبير حجم الأرقام النهائية
    color: '#007BFF', // لون مميز للمجموع النهائي
  },
  footer: {
    marginTop: 40,
    textAlign: 'center',
    fontSize: 10,
    color: '#555',
  },
});




const FilePdf = ({ order }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/admin-orders/${order?.id}`);
      setData(response.data);
    } catch (error) {
      console.error('Failed to fetch order data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (order?.id) {
      getData();
    }
  }, [order, order?.id]);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (!data) {
    return <Text>No data available</Text>;
  }

  
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <View>
            <Text style={{ fontSize: 24, fontWeight: 'bold', color: '#000' }}>
              ArabGiftCard.com
            </Text>
            <Text style={{ fontSize: 12, color: '#000' }}>
              SHOP NAME: ARAB GIFT CARD
            </Text>
          </View>
          <View style={styles.textRight}>
            <Text style={styles.invoiceTitle}>INVOICE</Text>
            <Text style={{ fontSize: 12, color: '#000' }}>
              INVOICE NUMBER: #{order?.id}
            </Text>
          </View>
        </View>

        {/* Customer Info */}
        <View style={styles.section}>
          <Text style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>
            Customer Info
          </Text>
          <Text style={{ paddingBottom: 6 }}>Name: {data?.order?.user?.name || 'N/A'}</Text>
          <Text style={{ paddingBottom: 6 }}>Phone: {data?.order?.user?.phone || 'N/A'}</Text>
          <Text style={{ paddingBottom: 6 }}>national id: {data?.order?.user?.national_id || 'N/A'}</Text>
          <Text style={{ paddingBottom: 6 }}>Date: {data?.order?.created_at || 'N/A'}</Text>
          <Text style={{ paddingBottom: 6 }}>Order Status: {data?.order?.status || 'N/A'}</Text>
          <Text style={{ paddingBottom: 6 }}>Order Payment: {data?.order?.payment_type || 'N/A'}</Text>
        </View>

        {/* Products Table */}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColHeader}>PRODUCTS</Text>
            <Text style={styles.tableColHeader}>QTY</Text>
            <Text style={styles.tableColHeader}>PRICE</Text>
            <Text style={styles.tableColHeader}>TOTAL</Text>
          </View>
          {data?.items?.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={styles.tableCol}>{item.item.name_en}</Text>
              <Text style={styles.tableCol}>{item.count}</Text>
              <Text style={styles.tableCol}>{item.item.price} SAR</Text>
              <Text style={styles.tableCol}>
                {item.item.price * item.count} SAR
              </Text>
            </View>
          ))}
        </View>

        {/* Totals Section */}
        <View style={styles.totalsSection}>
          <View style={styles.totalsRow}>
            <Text style={{ width: '33%' }}>Sub-total: </Text>
            <Text style={{ width: '25%', textAlign: 'right' }}>
              {data?.order?.total || 'N/A'} SAR
            </Text>
          </View>
          <View style={styles.totalsRow}>
            <Text style={{ width: '33%' }}>Tax: </Text>
            <Text style={{ width: '25%', textAlign: 'right' }}>0 SAR</Text>
          </View>
          <View style={styles.totalsRow}>
            <Text style={{ width: '33%' }}>Total: </Text>
            <Text style={{ width: '25%', textAlign: 'right' }}>
              {data?.order?.total || 'N/A'} SAR
            </Text>
          </View>
        </View>

        {/* Footer */}
        <View style={styles.footer}>
          <Text>
            If you require any assistance or have feedback, you can email us at
            support@arabgiftcard.com
          </Text>
          <Text>ArabGiftCard.com</Text>
        </View>
      </Page>
    </Document>
  );
};

export default FilePdf;
