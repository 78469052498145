import React, { useEffect, useState } from "react";
import EmailInput from "./components/EmailInput";
import PhoneInput from "./components/PhoneInput";
import { BiPhone } from "react-icons/bi";
import { MdOutlineEmail } from "react-icons/md";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export function Signin() {
  const [type, setType] = useState("phone");
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Signin",
    });
  }, [location.pathname]);

  return (
    <div className="d-flex w-100 flex-column align-items-center justify-content-center h-100">
      <div
        className=" p-4 d-flex flex-column align-items-center w-100"
        style={{
          // maxWidth: '400px',
          borderRadius: '15px',
          // boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
          border: 'none'
        }}
      >
        <h2 className="text-center mb-4">Signin </h2>
        <div className="btn-group w-100 mb-3" style={{ borderRadius: '10px', overflow: 'hidden' }}>
          <button
            className={`btn w-50 ${type === "phone" ? "active" : ""}`}
            onClick={() => setType("phone")}
            style={{
              borderRadius: '0',
              backgroundColor: type === "phone" ? '#007bff' : '#f0f0f0',
              color: type === "phone" ? '#fff' : '#000',
              border: 'none',
              pointerEvents: 'auto',  // يبقى الزر قابلًا للنقر
              outline: 'none',         // إزالة الإطار المحيط بالزر عند النقر
            }}
          >
            <BiPhone size={24} />
            <span className="ms-2">Phone</span>
          </button>
          <button
            className={`btn w-50 ${type === "email" ? "active" : ""}`}
            onClick={() => setType("email")}
            style={{
              borderRadius: '0',
              backgroundColor: type === "email" ? '#007bff' : '#f0f0f0',
              color: type === "email" ? '#fff' : '#000',
              border: 'none',
              pointerEvents: 'auto',  // يبقى الزر قابلًا للنقر
              outline: 'none',         // إزالة الإطار المحيط بالزر عند النقر
            }}
          >
            <MdOutlineEmail size={24} />
            <span className="ms-2">Email</span>
          </button>
        </div>
        <div className="w-100">
          {type === "email" ? <EmailInput /> : <PhoneInput />}
        </div>
      </div>
    </div>
  );
}
